<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import posHubsTab from './posHubsTab/index.vue';
import posStationsTab from "./posStationsTab/index.vue";
import subscriptionsTab from './subscriptionsTab/index.vue'
export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        PageHeader,
        posHubsTab,
        posStationsTab,
        subscriptionsTab
    },
    data() {
        return {


        };
    },
    methods: {

    },
    created() {

    },
};
</script>

<template>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.users.subItems.users_management')" />

    <b-tabs>

        <b-tab active>
            <template v-slot:title>

                <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block tab-text">{{
                    this.$t("user_profile.pos_hubs")
                }}</span>
            </template>
            <posHubsTab />

        </b-tab>


        <b-tab>
            <template v-slot:title>

                <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block tab-text">{{
                    this.$t("user_profile.pos_stations")
                }}</span>

            </template>
            <posStationsTab />


        </b-tab>
        <b-tab>
            <template v-slot:title>

                <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block tab-text">{{
                    this.$t("user_profile.subscriptions")
                }}</span>

            </template>

            <subscriptionsTab />

        </b-tab>


    </b-tabs>
</template>
