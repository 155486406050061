<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";


export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        PageHeader,
    },
    data() {
        return {

            posStations: [],
            posStations_page: 1,
            posStations_limit: 20,
            posStations_tot_page: 0,
            posStations_searchModel: '',
            PosStationsSearchMode: false,

            posStationsModal: {},
            current_pos_hub_id: ''

        };
    },
    methods: {




        /*  pos stations methods */
        getPosStations(page) {
            this.http
                .post("pos-stations/paginate", {
                    limit: this.posStations_limit,
                    page: page,
                    user_id: this.$route.params.id
                }, null, true)
                .then((res) => {
                    this.posStations_page = page
                    this.posStations_tot_page = Math.ceil(res.tot / this.posStations_limit);
                    this.posStations = res.data;
                });
        },


        searchPosStations() {
            this.PosStationsSearchMode = true;
            this.posStations_tot_page = 0;
            this.http
                .post("pos-stations/search", {
                    search: this.posStations_searchModel,
                    limit: this.posStations_limit,
                    page: this.posStations_page,
                    col: "name",
                    pos_hub_id: this.current_pos_hub_id

                })
                .then((res) => {
                    this.posStations = res.data;
                    this.posStations_tot_page = Math.ceil(res.tot / this.posStations_limit);

                });
        },
        cancelPosStationsSearchMode() {
            this.searchModel = "";
            this.PosStationsSearchMode = false;
            this.getPosStations(this.posStations_page);
        },
        openPosStationsModal(record) {
            this.current_pos_hub_id = record.id
            this.getPosStations(this.posStations_page)
            console.log(record)
            this.posStationsModal.show()
        },

        /* end */
    },
    created() {
        this.getPosStations(1);
    },
    mounted() {

    },
};
</script>

<template>
    <PageHeader v-if="!$route.params.id" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        :title="$t('menu.menuitems.pos_stations.subItems.pos_stations')" />

    <div class="row mt-3">
        <div class="col-6 mb-3">
            <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                    <input @keyup="searchPosStations()" type="text" class="form-control fa-lg text-light"
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')"
                        v-model="posStations_searchModel" style="background-color: #2a3042 !important" />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
            </div>
        </div>
        <div class="col-6" style="display: flex; justify-content: flex-end">
            <button class="btn-close text-light" v-if="PosStationsSearchMode"
                @click="cancelPosStationsSearchMode()"></button>

        </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
        <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
                <th scope="col">{{ $t("pos_hub.table.id") }}</th>
                <th scope="col">{{ $t("pos_stations.table.name") }}</th>
                <th scope="col">{{ $t("pos_stations.table.pos_type") }}</th>
                <th scope="col">{{ $t("pos_stations.table.register_number") }}</th>

                <th scope="col">{{ $t("pos_hub.table.package_type") }}</th>
                <th scope="col">{{ $t("pos_hub.table.is_active") }}</th>
                <th scope="col">{{ $t("pos_stations.table.subscription_start_date") }}</th>
                <th scope="col">{{ $t("pos_stations.table.subscription_end_date") }}</th>
                <th scope="col">{{ $t("pos_stations.table.brand") }}</th>
                <th scope="col">{{ $t("pos_stations.table.actions") }}</th>


            </tr>
        </thead>
        <tbody>
            <tr v-for="(app, index) in posStations" :key="app" class="text-center">
                <td>{{ index + 1 }}</td>
                <td>{{ app?.name }}</td>
                <td>{{ app && app?.pos_type ? app?.pos_type.name : '' }}</td>
                <td>{{ app?.register_number }}</td>
                <td>{{ $i18n.locale == 'en' ?
                    app?.pos_subscriptions[0]?.subscription_packge.name_en :
                    app?.pos_subscriptions[0]?.subscription_packge.name }}</td>

                <td>
                    <span class="" v-if="app?.is_active">
                        <img src="/assets/images/icons/check.svg" alt="img" />
                    </span>
                    <span class="" v-else>

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>

                <td v-if="app && app.pos_subscriptions[0].subscription_start_date">{{ new
                    Date(app?.created).toLocaleString('en-GB') }}</td>

                <td v-else>
                    <span class="">

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>
                <td v-if="app && app.pos_subscriptions[0].subscription_end_date">{{ new
                    Date(app?.created).toLocaleString('en-GB') }}</td>

                <td v-else>
                    <span class="">

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>
                <td style=" min-width: 100px;">
                    <img style="width: 64px; height: 64px" :src="app?.img ? `${$store.state.mediaUrl}/${app?.img?.split('public/')[1]
                        }` : '/assets/images/default_image.png'" alt="" />
                </td>
                <td>

                    <routerLink :to="{ name: 'posStation', params: { id: app.id } }" class="btn btn-light btn-sm me-2">

                        <span class="bx bx-user float-end fa-2x" style="color: #2a3042 !important"></span>
                    </routerLink>
                </td>


            </tr>
        </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div>
        <!--   Apps  Pagination     -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: posStations_page == 1 }">
                <a class="page-link" href="javascript: void(0);" @click="getPosStations(posStations_page - 1)"
                    aria-label="Previous">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
            </li>
            <li class="page-item" :class="{ active: p == posStations_page }" v-for="p in posStations_tot_page" :key="p">
                <a class="page-link" href="javascript: void(0);" @click="getPosStations(p)">{{
                    p
                }}</a>
            </li>

            <li class="page-item" :class="{ disabled: posStations_page == posStations_tot_page }">
                <a class="page-link" href="javascript: void(0);" @click="getPosStations(posStations_page + 1)"
                    aria-label="Next">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
            </li>
        </ul>
    </div>





    <!--end-->
</template>

<style scoped>
.btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
}
</style>
