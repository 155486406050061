<script>
import appConfig from "@/app.config";

export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {

    },
    data() {
        return {

            subscriptions: [],
            subscriptions_page: 1,
            subscriptions_limit: 20,
            subscriptions_tot_page: 0,
            subscriptions_searchModel: '',
            subscriptionsSearchMode: false,
            subscription_type: 'pos_stations'




        };
    },
    methods: {

        select_type() {
            console.log('g', this.subscription_type)
            this.getSubscriptions(this.subscriptions_page)
        },


        /*  pos stations methods */
        getSubscriptions(page) {
            this.http
                .get("users/get-subscriptions", {
                    page: page,
                    limit: this.subscriptions_limit,
                    user_id: this.$route.params.id,
                    type: this.subscription_type
                }, null, true)
                .then((res) => {
                    this.subscriptions_page = page
                    this.subscriptions_tot_page = Math.ceil(res.tot / this.subscriptions_limit);
                    this.subscriptions = res.data;
                });
        },


        searchSubscriptions() {
            this.subscriptionsSearchMode = true;
            this.subscriptions_tot_page = 0;
            this.http
                .get("users/search-subscriptions", {
                    search: this.subscriptions_searchModel,
                    page: this.subscriptions_page,
                    limit: this.subscriptions_limit,
                    user_id: this.$route.params.id,
                    type: this.subscription_type

                }, null, true)
                .then((res) => {
                    this.subscriptions = res.data;
                });
        },
        cancelSubscriptionsSearchMode() {
            this.searchModel = "";
            this.subscriptionsSearchMode = false;
            this.getSubscriptions(this.subscriptions_page);
        },


        /* end */
    },
    created() {
        this.getSubscriptions(1);
    },
    mounted() {

    },
};
</script>

<template>
    <div class="row mt-3">
        <div class="col-4 mb-3">
            <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                    <input @keyup="searchSubscriptions()" type="text" class="form-control fa-lg text-light"
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')"
                        v-model="subscriptions_searchModel" style="background-color: #2a3042 !important" />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
            </div>
        </div>
        <div class="col-4" style="display: flex; justify-content: flex-end">
            <button class="btn-close text-light" v-if="subscriptionsSearchMode"
                @click="cancelSubscriptionsSearchMode()"></button>

        </div>
        <div class="col-4 mb-3">

            <label for="" class="form-label">{{ $t('pos_subscriptions.type') }}</label>
            <select @change="select_type" v-model="subscription_type" class="form-select form-select-lg" name="" id="">

                <option value="pos_stations">{{ $t('pos_stations.title') }}</option>
                <option value="pos_hubs">{{ $t('pos_hub.title') }}</option>

            </select>
        </div>



    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
        <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
                <th scope="col">{{ $t("pos_subscriptions.no") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.id") }}</th>
                <th v-if="subscription_type == 'pos_stations'" scope="col">{{ $t("pos_subscriptions.pos_station") }}</th>
                <th v-else scope="col">{{ $t("pos_subscriptions.pos_hub") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.period") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.type") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.subscription_packge") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.total_price") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.upgrade_from_subscription_id") }}</th>

                <th scope="col">{{ $t("pos_stations.table.subscription_start_date") }}</th>
                <th scope="col">{{ $t("pos_stations.table.subscription_end_date") }}</th>
                <th scope="col">{{ $t("pos_stations.table.is_active") }}</th>
                <th scope="col">{{ $t("pos_stations.table.created") }}</th>


            </tr>
        </thead>
        <tbody>
            <tr v-for="(app, index) in subscriptions" :key="app" class="text-center">
                <td>{{ index + 1 }}</td>
                <td>{{ app?.id }}</td>
                <td v-if="subscription_type == 'pos_stations'">{{ app.pos_station_name }}</td>
                <td v-else>{{ app.pos_hub_name }}</td>
                <td>{{ app?.period }}</td>
                <td>{{
                    $i18n.locale == 'en' && app?.type == 'm' && app?.period == 1 ? "Month" :
                    $i18n.locale == 'en' && app?.type == 'm' ? "Months" :
                        $i18n.locale == 'en' && app?.type == 'y' && app?.period == 1 ? 'Year' :
                            $i18n.locale == 'en' && app?.type == 'y' ? 'Years' :
                                $i18n.locale == 'ar' && app?.type == 'm' && app?.period == 1 ? 'شهر' :
                                    $i18n.locale == 'ar' && app?.type == 'm' ? 'شهور' :
                                        $i18n.locale == 'ar' && app?.type == 'y' && app?.period == 1 ? 'سنة' :
                                            'سنوات'
                }}</td>
                <td>{{ $i18n.locale == 'en' ? app?.name_en : app?.name }}</td>
                <td>{{ app?.total_price }}</td>
                <td v-if="subscription_type == 'pos_stations'">{{ app?.upgrade_from_pos_subscription_id }}</td>
                <td v-else>{{ app?.upgrade_from_pos_hub_subscription_id }}</td>
                <td v-if="app && app?.subscription_start_date">{{ new
                    Date(app?.subscription_start_date).toLocaleString('en-GB') }}</td>
                <td v-else>

                    <span class="">

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>
                <td v-if="app && app?.subscription_start_date">{{ new
                    Date(app?.subscription_end_date).toLocaleString('en-GB') }}</td>
                <td v-else>

                    <span class="">

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>
                <td>
                    <span class="" v-if="app?.is_active">
                        <img src="/assets/images/icons/check.svg" alt="img" />
                    </span>
                    <span class="" v-else>

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>
                <td>
                    {{ new Date(app?.created).toLocaleString('en-GB') }}

                </td>




            </tr>
        </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div>
        <!--   Apps  Pagination     -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: subscriptions_page == 1 }">
                <a class="page-link" href="javascript: void(0);" @click="getSubscriptions(subscriptions_page - 1)"
                    aria-label="Previous">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
            </li>
            <li class="page-item" :class="{ active: p == subscriptions_page }" v-for="p in subscriptions_tot_page" :key="p">
                <a class="page-link" href="javascript: void(0);" @click="getSubscriptions(p)">{{
                    p
                }}</a>
            </li>

            <li class="page-item" :class="{ disabled: subscriptions_page == subscriptions_tot_page }">
                <a class="page-link" href="javascript: void(0);" @click="getSubscriptions(subscriptions_page + 1)"
                    aria-label="Next">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
            </li>
        </ul>
    </div>





    <!--end-->
</template>

<style scoped>
.btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
}
</style>
